import { FC, useEffect, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import Button from "../common/button";
import { termsModalEN } from "@/assets/dictionaries/en/cookies-content";
import { termsModalES } from "@/assets/dictionaries/es/cookies-content";

interface TermsConditionsModal extends Pick<ModalProps, "handleClose"> {}

const TermsModal: FC<TermsConditionsModal> = ({ handleClose }) => {
  const [termsContent, setTermsContent] = useState(termsModalEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setTermsContent(termsModalES);
        break;
      case "EN":
      default:
        setTermsContent(termsModalEN);
        break;
    }
  }, []); // Only run on mount

  return (
    <Modal handleClose={handleClose} size="large" header={termsContent.header}>
      <section>
        <p className="text-base mb-4">{termsContent.date}</p>
        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_1}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_1_1}</span>
          {termsContent.text_1_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_2_1}</span>
          {termsContent.text_2_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_3_1}</span>
          {termsContent.text_3_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_4_1}</span>
          {termsContent.text_4_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_5_1}</span>
          {termsContent.text_5_2}
        </p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_2}
        </p>

        <p className="pl-8 text-base mb-4">
          {termsContent.text_6_1}
          <span className="font-bold">{termsContent.text_6_2}</span>
          {termsContent.text_6_3}
        </p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_3}
        </p>

        <p className="pl-8 text-base mb-4">{termsContent.text_7}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_1_1}</span>
              {termsContent.list_1_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_2_1}</span>
              {termsContent.list_2_2}
            </p>
          </li>
        </ul>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_4}
        </p>
        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_8_1}</span>
          {termsContent.text_8_2}
          <span className="font-bold">{termsContent.text_8_3}</span>
          {termsContent.text_8_4}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_9}</span>
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_3_1}</span>
              {termsContent.list_3_2}
              <span className="font-bold">{termsContent.list_3_3}</span>
              {termsContent.list_3_4}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_4_1}</span>
              {termsContent.list_4_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_5_1}</span>
              {termsContent.list_5_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_6_1}</span>
              {termsContent.list_6_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{termsContent.list_7_1}</span>
              {termsContent.list_7_2}
            </p>
          </li>
        </ul>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_5}
        </p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_10}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_11}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">{termsContent.list_8}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_9}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_10}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_11}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_12}</p>
          </li>
        </ul>

        <p className="pl-8 text-base mb-4">{termsContent.text_12}</p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_13}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_14}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">{termsContent.list_13}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_14}</p>
          </li>
          <li>
            <p className="text-base mb-4">{termsContent.list_15}</p>
          </li>
        </ul>

        <p className="pl-8 text-base mb-4">{termsContent.text_15}</p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_16}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_17}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_18}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_6}
        </p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_19}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_20}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base">{termsContent.list_16}</p>
          </li>
          <li>
            <p className="text-base">{termsContent.list_17}</p>
          </li>
          <li>
            <p className="text-base">{termsContent.list_18}</p>
          </li>
        </ul>

        <p className="pl-8 text-base mb-4">{termsContent.text_21}</p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_22}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_23}</p>

        <p className="pl-8 text-base mb-4 font-bold">{termsContent.text_24}</p>

        <p className="pl-8 text-base mb-4">{termsContent.text_25}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_7}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_26_1}</span>
          {termsContent.text_26_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_27_1}</span>
          {termsContent.text_27_2}
        </p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_8}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_28_1}</span>
          {termsContent.text_28_2}
        </p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{termsContent.text_29_1}</span>
          {termsContent.text_29_2}
          <span className="font-bold">{termsContent.text_29_3}</span>
          {termsContent.text_29_4}
        </p>

        <p className="pl-4 text-base mb-4 font-bold">
          {termsContent.subtitle_9}
        </p>

        <p className="pl-8 text-base mb-4">
          {termsContent.text_30_1}
          <span className="font-bold">{termsContent.text_30_2}</span>
          {termsContent.text_30_3}
          <span className="font-bold">{termsContent.text_30_4}</span>
        </p>

        <div className="flex justify-center">
          <div>
            <Button onClick={handleClose}>{termsContent.button_close}</Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default TermsModal;
