interface FooterBarES {
  footer_contact: string;
  footer_follow_us: string;
  footer_site: string;
  footer_privacy: string;
  footer_terms: string;
  footer_cookie_policy: string;
  footer_cookies: string;
  footer_faq: string;
}

export const footerBarES: FooterBarES = {
  footer_contact: "Contacto",
  footer_follow_us: "Síguenos",
  footer_site: "Mapa de Sitio",
  footer_privacy: "Aviso de Privacidad",
  footer_terms: "Términos y Condiciones de Uso",
  footer_cookie_policy: "Política de Cookies",
  footer_cookies: "Gestionar Cookies",
  footer_faq: "Preguntas frecuentes",
};
