interface cookiesBannerEN {
  cookies_desc_1: string;
  cookies_desc_2: string;
  cookies_desc_3: string;
  button_accept: string;
  button_reject: string;
  button_read: string;
}

export const cookiesBannerEN: cookiesBannerEN = {
  cookies_desc_1:
    "This website uses third party cookies to enhance user experience. You an read more about them in our ",
  cookies_desc_2: " privacy policy",
  cookies_desc_3:
    ". Do you accept the terms and conditions with the use of cookies?",
  button_accept: "Accept all cookies",
  button_reject: "Reject all cookies",
  button_read: "Read more",
};

interface privacyModalEN {
  header: string;
  text_1: string;
  text_2: string;
  text_3: string;
  text_4: string;

  guide_1: string;

  text_5: string;

  subtitle_1: string;

  list_1: string;
  list_2: string;
  list_3: string;
  list_4: string;

  subtitle_2: string;

  list_5: string;
  list_6: string;
  list_7: string;
  list_8: string;
  list_9: string;

  text_6_1: string;
  text_6_2: string;
  text_6_3: string;
  text_7: string;

  guide_2: string;

  text_8: string;

  list_10_1: string;
  list_10_2: string;
  list_11_1: string;
  list_11_2: string;
  list_12: string;

  guide_3: string;

  text_9: string;

  guide_4: string;

  text_10: string;
  text_11: string;
  text_12: string;

  guide_5: string;

  text_13: string;
  text_14: string;
  text_15: string;
  text_16: string;
  text_17: string;

  guide_6: string;

  text_18: string;

  guide_7: string;

  text_19: string;
  text_20: string;

  list_13: string;
  list_14_1: string;
  list_14_2: string;
  list_14_3: string;

  text_21: string;

  guide_8: string;
  text_22: string;

  guide_9: string;
  text_23: string;

  text_24: string;
  text_25: string;
  text_26: string;
  text_27: string;
  text_28: string;

  text_29: string;

  text_30: string;
  text_31: string;
  text_32_1: string;
  text_32_2: string;
  text_32_3: string;

  title: string;

  text_33: string;

  text_34: string;

  text_35: string;

  text_36: string;
  text_37: string;

  text_38: string;

  button_close: string;
}

export const privacyModalEN: privacyModalEN = {
  header: "Privacy Notice",
  text_1: `In compliance with the provisions of the Federal Law on Protection of Personal Data Held by Private Parties (hereinafter, the "Law"), its Regulations, and the corresponding Guidelines, GBCI GROUP, S.A. de C.V. presents the following privacy notice. `,
  text_2: `GBCI GROUP, S.A. de C.V. is a Variable Capital Corporation specialized in organizational transformation and operational excellence based on Lean principles, regulated under Mexican law, with its registered address at Av. Ejercito Mexicano 501 Local 5, 6, and 7, Colonia Esfuerzo Nacional, Ciudad Madero, C.P. 89470 (hereinafter referred to as the "Controller"). In accordance with Articles 15, 16, 17, and related provisions of the Law, this privacy notice is made available to you regarding the personal data collected from you as the data subject (hereinafter, the "Data Subject").`,
  text_3: `The Controller deems it necessary to collect certain personal data to carry out activities inherent to its corporate purpose.`,
  text_4: `This privacy notice aims to ensure that you have full knowledge, control, and decision-making power over your personal data. Therefore, we recommend that you read the following information carefully:`,

  guide_1: "I.	Purpose of Personal Data Processing:",

  text_5: `The Controller will require personal data to fulfill its corporate purpose and will process them strictly for the following purposes:`,

  subtitle_1:
    "1.1. Necessary Purposes and/or Those Originating the Legal Relationship between the Controller and the Data Subject:",

  list_1: "Provide the requested goods and/or services.",
  list_2: "Maintain updated records to respond to inquiries.",
  list_3:
    "Fulfill contractual obligations between the Data Subject and the Controller.",
  list_4: "Comply with all legal obligations imposed on the Controller.",

  subtitle_2:
    "1.2 Purposes That Do Not Necessarily Originate the Legal Relationship Between the Controller and the Data Subject:",

  list_5:
    "Transfer or assign rights and/or obligations derived from the aforementioned contracts to third parties.",
  list_6: "Modify, if necessary, the indicated contracts.",
  list_7:
    "Prepare statistics and reports on the services provided by the Controller for internal monitoring.",
  list_8:
    "Conduct prospecting and promotion of goods and services related to the Controller's corporate purpose.",
  list_9: "Any analogous or compatible purpose with the above.",

  text_6_1: "If the ",
  text_6_2: "Data Subject ",
  text_6_3:
    "does not wish their personal data to be used for these secondary purposes, they may express their refusal through the means described in Section III of this notice.",
  text_7:
    "The personal data collected by the Data Controller, including but not limited to, will be those such as name, address, phone number, email, among others.",

  guide_2: "II.	Collected Personal Data:",

  text_8:
    "The personal data collected by the Controller, including but not limited to, are:",

  list_10_1: "Identification data: ",
  list_10_2: "Name, address, phone number, email, among others.",
  list_11_1: "Employment data: ",
  list_11_2: "Position, company, and work contact details.",
  list_12: "Other necessary data to fulfill the purposes mentioned herein.",

  guide_3:
    "III.	Options and Means to Limit the Use or Disclosure of Personal Data:",

  text_9: `The Controller protects personal data using restricted-access computer programs. Physical documents containing personal data are kept in locked files. Additionally, internal policies and staff training have been implemented to safeguard the confidentiality of the information.`,

  guide_4:
    "IV.	Means to Exercise Rights of Access, Rectification, Cancellation, or Opposition (ARCO Rights).",

  text_10: `The Data Subject may exercise their ARCO Rights by submitting a written request in accordance with Article 29 of the Law, either at the Controller’s registered address or via email at info@gbcigroup.com. The request must include: Name and address of the Data Subject, contact information, documents proving their identity, a clear and precise description of the rights they intend to exercise and any additional element to facilitate the identification of the data.`,
  text_11: `The Controller will respond to the request within 20 business days, extendable per legal provisions, and will inform the Data Subject of the determination taken.`,
  text_12: `The Data Subject understands and accepts that the cancellation and/or opposition to the use of their personal data may result in the inability of the Controller to maintain the legal relationship with them.`,

  guide_5: "V.	Personal Data Transfer.",

  text_13: `The Controller informs the Data Subject that their personal data may be transferred to national or foreign third parties under the following circumstances:`,
  text_14:
    "a) When the Controller transfers rights and/or obligations to a third party.",
  text_15: "b) When third-party services or advice are required.",
  text_16:
    "c) When legal, administrative, or debt collection services are needed.",
  text_17:
    "d) When required by manufacturers, suppliers, or distributors related to the service provided.",

  guide_6: "VI.	Security and Confidentiality.",

  text_18: `Personal data are safeguarded through administrative, technical, and physical security measures designed to prevent damage, loss, alteration, destruction, unauthorized use, improper access, or undue disclosure. For instance, data are stored in an internal database managed with periodically updated access credentials, restricted to authorized personnel. Confidentiality agreements with staff are also in place.`,

  guide_7: "VII.	ARCO Rights.",

  text_19: `The Data Subject has the right to access, rectify, cancel, or oppose the processing of their personal data, as well as revoke consent (jointly known as "ARCO Rights"). They may also request corrections to outdated, inaccurate, or incomplete information (Rectification), request the deletion of their data from records when inappropriate (Cancellation), or oppose its specific use (Opposition). To exercise any ARCO Rights, a request must be sent to info@gbcigroup.com. `,
  text_20:
    "The above information will also serve to understand the procedure and requirements for exercising ARCO rights. However, the request to exercise these rights must contain the following information:",

  list_13:
    "Name; phone number; email address. The response to the request will be provided within 30 business days and will be communicated via email.",
  list_14_1:
    "The contact details of the person or department responsible for handling ARCO rights requests are as follows:",
  list_14_2: "a) Responsible entity: Data Privacy Department.",
  list_14_3:
    "b) Address: Av. Ejercito Mexicano 501 Local 5, 6, and 7, Colonia Esfuerzo Nacional, Ciudad Madero, C.P. 89470",

  text_21:
    "At any time, the Data Subject may revoke their consent for the use of their personal data. However, revocation may not always result in immediate cessation if there are legal obligations requiring continued processing. Additionally, for certain purposes, revoking consent may mean the Controller is unable to provide the requested service.",

  guide_8: "VIII.	Privacy Notice Changes.",
  text_22: `If modifications to this privacy notice are required, they will be communicated to the Data Subject through publication at the registered address, the website https://gbcigroup.com/, or by email, in accordance with the Law.`,

  guide_9: "IX.	Use of Tracking Technologies on Our Website.",
  text_23: `Our website uses cookies, web beacons, and other tracking technologies to monitor user behavior and enhance the browsing experience.`,

  text_24: "The collected data may be used for:",
  text_25: "a) Facilitating website navigation and administration.",
  text_26: "b) Recognizing users and personalizing their experience.",
  text_27: "c) Identifying emails opened by users.",
  text_28: "d) Analyzing visited pages.",

  text_29:
    "The collected data may include device information and geolocation. If you do not wish to share this information, you may disable tracking technologies in your device settings.",

  text_30: "The personal data obtained are: ",
  text_31: "(a) from devices you use; and (b) geolocation (location).",
  text_32_1:
    "To learn how to disable these technologies, please refer to section ",
  text_32_2: "III (Third) ",
  text_32_3: "of this Privacy Notice.",

  title: "CONSENT OF THE DATA SUBJECT",

  text_33: `Pursuant to Article 8 of the Law, I consent to provide my personal data to the Controller for processing under this Privacy Notice.`,

  text_34: `Pursuant to the second paragraph of Article 36 of the Law, I accept the transfer of my personal data to third parties as necessary under Section IV of this Privacy Notice.`,

  text_35: `Additionally, I consent to the use of my personal data for secondary purposes not essential to my relationship with the Controller.`,

  text_36: "Inquiries and Clarifications",
  text_37: `For any inquiries or clarifications regarding this Privacy Notice, please contact our Privacy Department at info@gbcigroup.com.`,

  text_38: "Last Updated: March 24, 2025",

  button_close: "Close",
};

interface termsModalEN {
  header: string;
  date: string;
  subtitle_1: string;
  text_1_1: string;
  text_1_2: string;
  text_2_1: string;
  text_2_2: string;
  text_3_1: string;
  text_3_2: string;
  text_4_1: string;
  text_4_2: string;
  text_5_1: string;
  text_5_2: string;

  subtitle_2: string;
  text_6_1: string;
  text_6_2: string;
  text_6_3: string;

  subtitle_3: string;
  text_7: string;
  list_1_1: string;
  list_1_2: string;
  list_2_1: string;
  list_2_2: string;

  subtitle_4: string;
  text_8_1: string;
  text_8_2: string;
  text_8_3: string;
  text_8_4: string;

  text_9: string;

  list_3_1: string;
  list_3_2: string;
  list_3_3: string;
  list_3_4: string;
  list_4_1: string;
  list_4_2: string;
  list_5_1: string;
  list_5_2: string;
  list_6_1: string;
  list_6_2: string;
  list_7_1: string;
  list_7_2: string;

  subtitle_5: string;
  text_10: string;
  text_11: string;

  list_8: string;
  list_9: string;
  list_10: string;
  list_11: string;
  list_12: string;

  text_12: string;

  text_13: string;

  text_14: string;
  list_13: string;
  list_14: string;
  list_15: string;

  text_15: string;

  text_16: string;
  text_17: string;
  text_18: string;

  subtitle_6: string;
  text_19: string;
  text_20: string;

  list_16: string;
  list_17: string;
  list_18: string;

  text_21: string;

  text_22: string;
  text_23: string;

  text_24: string;
  text_25: string;

  subtitle_7: string;

  text_26_1: string;
  text_26_2: string;

  text_27_1: string;
  text_27_2: string;

  subtitle_8: string;

  text_28_1: string;
  text_28_2: string;

  text_29_1: string;
  text_29_2: string;
  text_29_3: string;
  text_29_4: string;

  subtitle_9: string;

  text_30_1: string;
  text_30_2: string;
  text_30_3: string;
  text_30_4: string;

  button_close: string;
}

export const termsModalEN: termsModalEN = {
  header: "Terms and Conditions of Use",
  button_close: "Close",
  date: "Last Updated: March 24, 2025",
  subtitle_1: "1.	GENERAL INFORMATION",
  text_1_1: "Responsible Party: ",
  text_1_2: `Information Technology Department (hereinafter "the Company").`,
  text_2_1: "Address: ",
  text_2_2:
    "Av. Ejército Mexicano 501, Local 5, 6, and 7, Colonia Esfuerzo Nacional, Ciudad Madero, C.P. 89470.",
  text_3_1: "Contact: ",
  text_3_2: "Information Technology Department",
  text_4_1: "Email: ",
  text_4_2: "info@gbcigroup.com",
  text_5_1: "Phone: ",
  text_5_2: "+52 833 160 8894",

  subtitle_2: "2.	PURPOSE",
  text_6_1:
    "The purpose of these Terms and Conditions is to establish the rules and guidelines for the use of the services and the purchase of products offered by ",
  text_6_2: "GBCI Group S.A. de C.V., ",
  text_6_3:
    "as well as to define the rights and responsibilities of both the Company and the users.",

  subtitle_3: "3.	DEFINITIONS",
  text_7:
    "For the purposes of these Terms and Conditions, the following definitions apply:",
  list_1_1: "Company: ",
  list_1_2: "GBCI Group S.A. de C.V.",
  list_2_1: "User: ",
  list_2_2:
    "Any individual interacting with the Company, whether to buy, sell, reserve products, or engage in any other commercial transaction.",

  subtitle_4: "4.	NATURE OF THE BUSINESS",
  text_8_1: "4.1 GBCI Group ",
  text_8_2:
    "is a boutique consulting firm specializing in customized transformation efforts based on Lean principles, referred to as ",
  text_8_3: `"Impetus"`,
  text_8_4:
    ". Its goal is to be a strategic ally for its clients, helping them identify, develop, and enhance their value within their industry and beyond.",

  text_9: `4.2 The "Impetus" offerings include:`,

  list_3_1: "Continuous Improvement: ",
  list_3_2: "Implementation of ",
  list_3_3: "Kaizen and Lean ",
  list_3_4:
    "methodologies for incremental optimization of products and processes.",
  list_4_1: "Daily Management: ",
  list_4_2:
    "A metric-based management system that establishes a cadence for daily meetings and communication, improving accountability and facilitating problem-solving.",
  list_5_1: "Innovation: ",
  list_5_2:
    "Assisting clients in implementing methodologies to identify or develop innovative products and services that can transform their industry or expand into new markets.",
  list_6_1: "Transformation: ",
  list_6_2:
    "A radical redesign of a value chain to achieve innovative goals, including reevaluating the use of current technology, realigning organizational structures, and improving process standards.",
  list_7_1: "Process Automation: ",
  list_7_2:
    "Transitioning transformed processes to automation using various technologies such as RPA, AI, and software development to provide intuitive and valuable user experiences.",

  subtitle_5: "5. TERMS OF USE FOR THE WEBSITE AND SERVICES",
  text_10: "5.1 Permitted Use",
  text_11:
    "Users of the Company’s website agree to use the platform and services responsibly and in compliance with applicable laws. The following actions are strictly prohibited:",

  list_8: "Using the site for illegal, fraudulent, or harmful activities.",
  list_9:
    "Introducing or distributing computer viruses, malware, or any other malicious code.",
  list_10:
    "Accessing Company or other users’ systems, data, or information without authorization.",
  list_11:
    "Collecting personal information from other users without their consent.",
  list_12:
    "Engaging in actions that could compromise the security, integrity, or functionality of the website.",

  text_12:
    "The Company reserves the right to restrict, suspend, or terminate access to the site and services for users or accounts that violate these provisions.",

  text_13: "5.2 User Registration",

  text_14:
    "To access certain services or features, a user account may be required. The registration process involves:",
  list_13:
    "Providing truthful, complete, and up-to-date information, including full name, email address, and other relevant details.",
  list_14:
    "Selecting secure and confidential login credentials. Users are responsible for maintaining the confidentiality of their password and all activities conducted under their account.",
  list_15:
    "Immediately notifying the Company of any unauthorized account use or security breach.",

  text_15:
    "The Company is not responsible for losses or damages resulting from the user’s failure to comply with these obligations or misuse of their credentials.",

  text_16: "5.3 Intellectual Property",
  text_17:
    "All content on the Company’s website, including but not limited to text, images, graphics, logos, icons, videos, software, and trademarks, is the exclusive property of the Company or its licensors and is protected by applicable intellectual property laws.",
  text_18:
    "Reproduction, distribution, modification, public display, transmission, or any other use of the content without prior written consent from the Company is prohibited. Unauthorized use may result in legal action.",

  subtitle_6: "6.	PAYMENT POLICIES",
  text_19: "6.1 Payment Methods",
  text_20:
    "Payments must be made directly to the Company through the following methods:",

  list_16: "Credit and debit cards.",
  list_17: "Bank transfers.",
  list_18: "Other methods agreed upon with the Company.",

  text_21:
    "The user is responsible for providing correct payment information. The Company may reject transactions if fraud is suspected.",

  text_22: "6.2 Invoicing",
  text_23:
    "To request an invoice, the client must provide their tax details within the timeframe established by applicable law. The Company will send the invoice to the registered email address.",

  text_24: "6.3 Payment Processing",
  text_25:
    "Once payment is confirmed, the service or product will be processed accordingly. Processing times may vary and will be communicated as needed.",

  subtitle_7: "7.	LIMITATION OF LIABILITY",

  text_26_1: "7.1 Disclaimer of Warranties: ",
  text_26_2:
    "The Company does not guarantee that the website or services will be free of errors or interruptions.",

  text_27_1: "7.2 Use of Services at User’s Own Risk: ",
  text_27_2:
    "The user assumes full responsibility for the use of the products and services purchased.",

  subtitle_8: "8.	DATA PROTECTION AND PRIVACY",

  text_28_1: "8.1 Privacy Notice: ",
  text_28_2:
    "The privacy policies established in the corresponding privacy notice apply.",

  text_29_1: "8.2 Confidentiality: ",
  text_29_2:
    "User information will be protected in accordance with applicable privacy laws and ",
  text_29_3: "will not be shared without consent",
  text_29_4: ", except where legally required.",

  subtitle_9: "9.	GOVERNING LAW AND JURISDICTION",

  text_30_1: "These Terms and Conditions are governed by the laws of the ",
  text_30_2: "United Mexican States",
  text_30_3:
    ". Any dispute arising from this document shall be submitted to the competent courts in ",
  text_30_4: "Ciudad Madero, Tamaulipas.",
};

interface cookiesModalEN {
  header: string;
  text_1: string;

  subtitle_1: string;
  text_2: string;

  subtitle_2: string;
  text_3: string;

  list_1_1: string;
  list_1_2: string;
  list_2_1: string;
  list_2_2: string;
  list_3_1: string;
  list_3_2: string;

  subtitle_3: string;
  list_4_1: string;
  list_4_2: string;
  list_5_1: string;
  list_5_2: string;
  list_6_1: string;
  list_6_2: string;
  list_7_1: string;
  list_7_2: string;

  subtitle_4: string;
  text_4: string;

  subtitle_5: string;
  text_5: string;

  subtitle_6: string;
  text_6: string;

  subtitle_7: string;
  text_7: string;

  subtitle_8: string;
  text_8: string;
  text_9_1: string;
  text_9_2: string;
  text_10_1: string;
  text_10_2: string;
  text_11_1: string;
  text_11_2: string;
  text_12: string;

  text_13_1: string;
  text_13_2: string;

  button_close: string;
}

export const cookiesModalEN: cookiesModalEN = {
  header: "Cookie Policy",
  text_1: `Welcome to GBCI Group S.A. de C.V. and its associated brands ("We," "our," "us," or "GBCI Group"). We use cookies and similar technologies when you visit our websites and digital platforms. By using our services, you agree to the use of cookies as described in this policy.`,

  subtitle_1: "1.	What are cookies? ",
  text_2:
    "Cookies are small text files stored on your device when you visit our websites. These files contain information used to enhance the user experience and collect data on browsing behavior.",

  subtitle_2: "2.	How do we use cookies?",
  text_3: "GBCI Group uses cookies for the following purposes:",

  list_1_1: "User experience enhancement: ",
  list_1_2:
    "We use cookies to personalize your experience on our websites, remember your preferences, and facilitate navigation.",
  list_2_1: "Analytics and performance: ",
  list_2_2:
    "We use cookies to collect analytical data that helps us understand how users interact with our websites and improve their performance.",
  list_3_1: "Personalized advertising: ",
  list_3_2:
    "Cookies may be used to display personalized ads based on your interests and browsing behavior.",

  subtitle_3: "3.	Types of cookies we use:",
  list_4_1: "Essential cookies: ",
  list_4_2: "Necessary for the basic functioning of the website.",
  list_5_1: "Performance cookies: ",
  list_5_2:
    "Help us improve website functionality by collecting information on how it is used.",
  list_6_1: "Functionality cookies: ",
  list_6_2:
    "Allow the website to remember your preferences and choices to provide a personalized experience.",
  list_7_1: "Advertising cookies: ",
  list_7_2: "Used to display relevant and personalized ads.",

  subtitle_4: "4.	Cookie control:",
  text_4:
    "You can manage your cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our websites.",

  subtitle_5: "5.	Sharing information with third parties:",
  text_5:
    "In some cases, GBCI Group may share data collected through cookies with third parties to improve our services and offer more relevant advertising. However, user data collected is exclusively for GBCI Group’s database and is not transmitted to third parties.",

  subtitle_6: "6.	Relationship with GBCI Group:",
  text_6:
    "This policy applies to all domains and intangible assets owned by GBCI Group and/or its subsidiaries.",

  subtitle_7: "7.	Changes to the cookie policy: ",
  text_7:
    "GBCI Group reserves the right to modify this Cookie Policy at any time. Any significant changes will be notified through our websites.",

  subtitle_8: "8.	Contact:",
  text_8:
    "If you have any questions about this Cookie Policy, you can contact us at:",
  text_9_1: "Responsible department: ",
  text_9_2: "Information Technology",
  text_10_1: "Email: ",
  text_10_2: "info@gbcigroup.com",
  text_11_1: "Phone: ",
  text_11_2: "+52 833 160 8894",
  text_12:
    "By using our services, you agree to the use of cookies in accordance with this Cookie Policy.",

  text_13_1: "Last updated: ",
  text_13_2: "March 24, 2025 ",

  button_close: "Close",
};
