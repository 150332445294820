interface cookiesBannerES {
  cookies_desc_1: string;
  cookies_desc_2: string;
  cookies_desc_3: string;
  button_accept: string;
  button_reject: string;
  button_read: string;
}

export const cookiesBannerES: cookiesBannerES = {
  cookies_desc_1:
    "Este sitio web utiliza cookies de terceros para mejorar la experiencia del usuario. Puedes leer más sobre ellas en nuestra ",
  cookies_desc_2: " política de privacidad",
  cookies_desc_3:
    ". ¿Aceptas los términos y condiciones con el uso de cookies?",
  button_accept: "Aceptar cookies",
  button_reject: "Rechazar cookies",
  button_read: "Leer más",
};

interface privacyModalES {
  header: string;
  text_1: string;
  text_2: string;
  text_3: string;
  text_4: string;

  guide_1: string;

  text_5: string;

  subtitle_1: string;

  list_1: string;
  list_2: string;
  list_3: string;
  list_4: string;

  subtitle_2: string;

  list_5: string;
  list_6: string;
  list_7: string;
  list_8: string;
  list_9: string;

  text_6_1: string;
  text_6_2: string;
  text_6_3: string;
  text_7: string;

  guide_2: string;

  text_8: string;

  list_10_1: string;
  list_10_2: string;
  list_11_1: string;
  list_11_2: string;
  list_12: string;

  guide_3: string;

  text_9: string;

  guide_4: string;

  text_10: string;
  text_11: string;
  text_12: string;

  guide_5: string;

  text_13: string;
  text_14: string;
  text_15: string;
  text_16: string;
  text_17: string;

  guide_6: string;

  text_18: string;

  guide_7: string;

  text_19: string;
  text_20: string;

  list_13: string;
  list_14_1: string;
  list_14_2: string;
  list_14_3: string;

  text_21: string;

  guide_8: string;
  text_22: string;

  guide_9: string;
  text_23: string;

  text_24: string;
  text_25: string;
  text_26: string;
  text_27: string;
  text_28: string;

  text_29: string;

  text_30: string;
  text_31: string;
  text_32_1: string;
  text_32_2: string;
  text_32_3: string;

  title: string;

  text_33: string;

  text_34: string;

  text_35: string;

  text_36: string;
  text_37: string;

  text_38: string;

  button_close: string;
}

export const privacyModalES: privacyModalES = {
  header: "Aviso de Privacidad",
  text_1:
    "Para cumplir con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo, la “Ley”), su Reglamento y los Lineamientos correspondientes, GBCI GROUP, S.A. de C.V., le presenta el siguiente aviso de privacidad.",
  text_2:
    "Manifiesta que es una Sociedad Anónima de Capital Variable especializada en transformación organizacional y excelencia operacional basada en principios Lean, regulada conforme a la legislación mexicana, teniendo su domicilio en Av. Ejercito Mexicano 501 Local 5 6 y 7 Colonia Esfuerzo Nacional Ciudad Madero CP. 89470 (denominada en lo sucesivo la “Responsable”), con fundamento en los artículos 15°, 16°, 17° y demás correlativos y aplicables de la Ley, pone a su disposición el presente aviso de privacidad, en virtud de los datos personales que se pretenden recabar de Usted como titular de los mismos (en lo sucesivo, el “Titular”).",
  text_3:
    "Para la Responsable, es necesaria la recopilación de ciertos datos personales para llevar a cabo las actividades intrínsecas a su objeto social.",
  text_4:
    "Todo lo anterior se realiza con el objetivo de que usted tenga pleno conocimiento, control y decisión sobre sus datos personales, por ello recomendamos que lea atentamente la siguiente información:",

  guide_1: "I.	Finalidad del tratamiento de los datos personales:",

  text_5:
    "La Responsable, para la consecución de su objeto social, requerirá datos personales que serán tratados estrictamente para alcanzar las siguientes finalidades:",

  subtitle_1:
    "1.1. Finalidades necesarias y/o que dan origen a la relación jurídica entre la Responsable y el Titular:",

  list_1: "Proveer el bien y/o servicio que solicite.",
  list_2:
    "Mantener actualizados nuestros registros a fin de poder responderle a sus consultas.",
  list_3:
    "Dar cumplimiento a las obligaciones contractuales pactadas entre el Titular y la Responsable.",
  list_4:
    "Cumplir con todas las obligaciones a cargo de la Responsable que le imponga la legislación vigente.",

  subtitle_2:
    "1.2 Finalidades que no dan necesariamente origen a la relación jurídica entre la Responsable y el Titular:",

  list_5:
    "Ceder o transmitir a un tercero, mediante cualquier forma legal, los derechos y/u obligaciones derivados de los contratos antes señalados.",
  list_6: "Modificar, en su caso, los contratos señalados.",
  list_7:
    "Elaborar estadísticas y reportes de los servicios prestados por la Responsable con el objeto de llevar un control interno de dichos servicios, así como para dar seguimiento puntual a los mismos.",
  list_8:
    "Realizar prospección y promoción de bienes y servicios relacionados al objeto social de la Responsable.",
  list_9: "Cualquier finalidad análoga o compatible con las anteriores.",

  text_6_1: "En caso de que el ",
  text_6_2: "Titular ",
  text_6_3:
    "no desee que sus datos personales se utilicen para estos fines secundarios, podrá manifestar su no consentimiento a través de los medios descritos en el punto III (tercero) de este aviso.",
  text_7:
    "Los datos personales que la Responsable recaba, de manera enunciativa y no limitativa, serán aquellos como nombre, domicilio, número telefónico, correo electrónico, entre otros.",

  guide_2: "II.	Datos personales recabados:",

  text_8:
    "Los datos personales que la Responsable recaba, de manera enunciativa y no limitativa, incluyen:",

  list_10_1: "Datos de identificación: ",
  list_10_2:
    "nombre, domicilio, número telefónico, correo electrónico entre otros.",
  list_11_1: "Datos laborales: ",
  list_11_2: "información de su puesto, empresa y contacto laboral.",
  list_12:
    "Cualquier otro dato que sea necesario para cumplir con las finalidades aquí mencionadas.",

  guide_3:
    "III.	Opciones y medios que la Responsable ofrece para limitar el uso o divulgación de datos personales:",

  text_9:
    "Con el objeto de limitar el uso o divulgación de los datos personales, la Responsable resguarda dichos datos personales bajo programas de cómputo con acceso limitado únicamente a personas con facultades para ello. Por lo que respecta a documentos físicos que contengan datos personales del Titular, la Responsable los resguarda en archivos bajo llave. De igual modo, la Responsable ha implementado capacitación, así como políticas y procedimientos internos aplicables a su personal.",

  guide_4:
    "IV.	Medios para ejercer los derechos de acceso, rectificación, cancelación u oposición.",

  text_10:
    "El Titular de los datos personales podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición presentando una solicitud por escrito en términos del artículo 29° de la Ley, en el propio domicilio de la Responsable o por medio del correo electrónico info@gbcigroup.com, el cual deberá contener el nombre y domicilio del titular, información de contacto, documentos que acrediten su personalidad, una descripción clara y precisa de los derechos que se pretenden ejercer y cualquier otro elemento que facilite la localización de los datos.",
  text_11:
    "La Responsable, en un plazo máximo de 20 (veinte) días hábiles, prorrogables en términos de ley, a partir de haber recibido la solicitud, informará al Titular la determinación adoptada.",
  text_12:
    "El Titular entiende, reconoce y acepta que la cancelación y/u oposición al uso de sus datos personales genera la imposibilidad de que la Responsable pueda ejercitar la relación jurídica que sostendrá con el Titular, para el cumplimiento de las obligaciones inherentes a su objeto social y demás que contraiga con éste.",

  guide_5: "V.	Transferencia de datos personales.",

  text_13:
    "La Responsable comunica al Titular, y este acepta, que podrá transferir sus datos personales a terceros nacionales o extranjeros en los siguientes supuestos:",
  text_14:
    "a) Cuando decida ceder a un tercero, mediante cualquier forma legal, los derechos y/u obligaciones que tenga frente al Titular.",
  text_15:
    "b) Cuando requiera de un tercero una prestación de servicios o asesoría respecto de los servicios que son ofrecidos.",
  text_16:
    "c) Cuando requiera de un tercero una prestación de servicios o asesoría jurídica relacionada con la administración y cobranza judicial y extrajudicial y el ejercicio de acciones legales.",
  text_17:
    "d) Cuando, en su caso, los requiera el fabricante, proveedor o distribuidor del bien con el que se relacione.",

  guide_6: "VI.	De la Seguridad y Confidencialidad.",

  text_18:
    "Los Datos Personales y su confidencialidad están resguardados mediante medidas de seguridad administrativas, técnicas y/o físicas diseñadas para prevenir daños, pérdidas, alteraciones, destrucción, uso no autorizado, acceso indebido o divulgación indebida. Por ejemplo, los Datos Personales se almacenan en una base de datos interna, cuya administración se realiza mediante claves de acceso que se actualizan periódicamente, y el acceso está restringido a personal autorizado. Además, se han establecido acuerdos de confidencialidad con el personal, entre otras medidas.",

  guide_7: "VII.	Derechos ARCO.",

  text_19: `Usted tiene el derecho de acceder, rectificar, cancelar u oponerse al tratamiento de sus Datos Personales, así como revocar su consentimiento (conjuntamente conocidos como "Derechos ARCO"). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico: info@gbcigroup.com.`,
  text_20:
    "Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:",

  list_13:
    "Nombre; teléfono; correo electrónico. La respuesta a la solicitud se dará en un plazo de 30 días hábiles, y se comunicará por correo electrónico.",
  list_14_1:
    "Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:",
  list_14_2:
    "a) Nombre del responsable: Dirigir al departamento de datos personales.",
  list_14_3:
    "b) Domicilio: Av. Ejercito Mexicano 501 Local 5 6 y 7 Colonia Esfuerzo Nacional Ciudad Madero CP. 89470.",

  text_21:
    "Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal se requiera seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.",

  guide_8: "VIII.	Cambios al aviso de privacidad.",
  text_22:
    "En el supuesto de que la Responsable requiera modificar el contenido del presente aviso de privacidad, lo hará del conocimiento del Titular a través de la publicación del mismo en el domicilio mencionado en el primer párrafo, su portal de internet https://gbcigroup.com/ o por correo electrónico, aplicando en lo conducente lo establecido en la propia Ley, y en su caso, para la manifestación de la voluntad del Titular, lo cual aplicará únicamente para los aspectos del tratamiento de datos personales diversos de los ya consentidos en este aviso de privacidad.",

  guide_9: "IX.	Uso de tecnologías de rastreo en nuestro portal de internet.",
  text_23:
    "Le informamos que en nuestra página de internet utilizamos cookies, web beacons y otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página.",

  text_24:
    "Los datos personales que recabamos a través de estas tecnologías serán utilizados para:",
  text_25:
    "a) Facilitar la administración y navegación de los usuarios de nuestro sitio web.",
  text_26:
    "b) Reconocer a los usuarios y personalizar su visita en los sitios web.",
  text_27: "c) Determinar qué correos han sido abiertos por los usuarios.",
  text_28: "d) Conocer las páginas que visita el usuario.",

  text_29:
    "Los datos personales obtenidos incluyen, entre otros, información del dispositivo que utiliza y su ubicación geográfica. Si no desea compartir esta información, puede deshabilitar estas tecnologías configurando su dispositivo.",

  text_30: "Los datos personales obtenidos son: ",
  text_31:
    "(a) de dispositivos que usted usa; y (b) geolocalización (ubicación).",
  text_32_1:
    "Para conocer la forma en que se pueden deshabilitar estas tecnologías, consulte el punto ",
  text_32_2: "III (Tercero) ",
  text_32_3: "del presente Aviso de Privacidad.",

  title: "CONSENTIMIENTO DEL TITULAR",

  text_33:
    "Con fundamento en el artículo 8° de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, consiento en entregar a la Responsable mis datos personales para que sean tratados conforme a lo consignado en el presente Aviso de Privacidad que fue puesto a mi disposición.",

  text_34:
    "Asimismo, con fundamento en el segundo párrafo del artículo 36 del ordenamiento señalado, aceptó la transferencia de mis datos personales a los terceros que resulten necesarios conforme el numeral IV (cuarto) del Aviso de Privacidad.",

  text_35:
    "Por otra parte, el Titular acepta y consiente que sus datos personales sean utilizados para las finalidades que no son necesarias y que no dan origen a su relación con la Responsable.",

  text_36: "Consultas y Aclaraciones",
  text_37:
    "Para cualquier pregunta o aclaración respecto a este Aviso de Privacidad, le invitamos a ponerse en contacto con nuestro departamento de privacidad a través del siguiente correo electrónico: info@gbcigroup.com.",

  text_38: "Última actualización: 24 de Marzo de 2025",

  button_close: "Cerrar",
};

interface termsModalES {
  header: string;
  date: string;
  subtitle_1: string;
  text_1_1: string;
  text_1_2: string;
  text_2_1: string;
  text_2_2: string;
  text_3_1: string;
  text_3_2: string;
  text_4_1: string;
  text_4_2: string;
  text_5_1: string;
  text_5_2: string;

  subtitle_2: string;
  text_6_1: string;
  text_6_2: string;
  text_6_3: string;

  subtitle_3: string;
  text_7: string;
  list_1_1: string;
  list_1_2: string;
  list_2_1: string;
  list_2_2: string;

  subtitle_4: string;
  text_8_1: string;
  text_8_2: string;
  text_8_3: string;
  text_8_4: string;

  text_9: string;

  list_3_1: string;
  list_3_2: string;
  list_3_3: string;
  list_3_4: string;
  list_4_1: string;
  list_4_2: string;
  list_5_1: string;
  list_5_2: string;
  list_6_1: string;
  list_6_2: string;
  list_7_1: string;
  list_7_2: string;

  subtitle_5: string;
  text_10: string;
  text_11: string;

  list_8: string;
  list_9: string;
  list_10: string;
  list_11: string;
  list_12: string;

  text_12: string;

  text_13: string;

  text_14: string;
  list_13: string;
  list_14: string;
  list_15: string;

  text_15: string;

  text_16: string;
  text_17: string;
  text_18: string;

  subtitle_6: string;
  text_19: string;
  text_20: string;

  list_16: string;
  list_17: string;
  list_18: string;

  text_21: string;

  text_22: string;
  text_23: string;

  text_24: string;
  text_25: string;

  subtitle_7: string;

  text_26_1: string;
  text_26_2: string;

  text_27_1: string;
  text_27_2: string;

  subtitle_8: string;

  text_28_1: string;
  text_28_2: string;

  text_29_1: string;
  text_29_2: string;
  text_29_3: string;
  text_29_4: string;

  subtitle_9: string;

  text_30_1: string;
  text_30_2: string;
  text_30_3: string;
  text_30_4: string;

  button_close: string;
}

export const termsModalES: termsModalES = {
  header: "Términos y Condiciones de Uso",
  button_close: "Cerrar",
  date: "Última Actualización: 24 de Marzo de 2025",
  subtitle_1: "1.	INFORMACIÓN GENERAL",
  text_1_1: "Responsable: ",
  text_1_2: `Departamento de tecnologías de la información (en adelante "la Empresa").`,
  text_2_1: "Domicilio: ",
  text_2_2:
    "Av. Ejército Mexicano 501, Local 5 6 y 7, Colonia Esfuerzo Nacional, Ciudad Madero, C.P. 89470.",
  text_3_1: "Contacto: ",
  text_3_2: "Departamento de tecnologías de la información.",
  text_4_1: "Correo: ",
  text_4_2: "info@gbcigroup.com",
  text_5_1: "Teléfono: ",
  text_5_2: "+52 833 160 8894",

  subtitle_2: "2.	OBJETO",
  text_6_1:
    "El objetivo de estos Términos y Condiciones es establecer las reglas y directrices para el uso de los servicios y la compra de productos ofrecidos por ",
  text_6_2: "GBCI Group S.A. de C.V., ",
  text_6_3:
    "así como definir los derechos y responsabilidades tanto de La Empresa como de los usuarios.",

  subtitle_3: "3.	DEFINICIONES",
  text_7: "Para efectos de estos Términos y Condiciones, se entenderá por:",
  list_1_1: "Empresa: ",
  list_1_2: "GBCI Group S.A. de C.V.",
  list_2_1: "Usuario: ",
  list_2_2:
    "Toda persona que interactúe con La Empresa, ya sea para comprar, vender, apartar productos o participar en cualquier otra operación comercial.",

  subtitle_4: "4.	NATURALEZA DEL NEGOCIO",
  text_8_1: "4.1 GBCI Group ",
  text_8_2:
    "es una firma de consultoría boutique que se especializa en esfuerzos de transformación personalizados basados en principios Lean, denominados ",
  text_8_3: `"Impetus"`,
  text_8_4:
    ". Su objetivo es ser un aliado estratégico para sus clientes, ayudándolos a identificar, desarrollar y mejorar su valor en su industria y más allá.",

  text_9: `4.2 Los "Impetus" que ofrecen incluyen:`,

  list_3_1: "Mejora continua: ",
  list_3_2: "Implementación de metodologías ",
  list_3_3: "Kaizen y Lean ",
  list_3_4: "para optimización incremental de productos y procesos.",
  list_4_1: "Gestión diaria: ",
  list_4_2:
    "Sistema de gestión basado en métricas que define una cadencia para reuniones diarias y comunicación, mejorando la rendición de cuentas y facilitando la resolución de problemas.",
  list_5_1: "Innovación: ",
  list_5_2:
    "Ayuda a los clientes a implementar metodologías para identificar o desarrollar productos y servicios innovadores que puedan transformar su industria o expandirse a otros mercados.",
  list_6_1: "Transformación: ",
  list_6_2:
    "Rediseño radical de una cadena de valor para lograr objetivos innovadores, incluyendo la reevaluación del uso de tecnología actual, realineación de la estructura organizacional y mejora de estándares de procesos.",
  list_7_1: "Automatización de procesos: ",
  list_7_2:
    "Transición de procesos transformados hacia la automatización utilizando diversas tecnologías como RPA, IA y desarrollo de software para proporcionar experiencias de usuario intuitivas y valiosas.",

  subtitle_5: "5.	CONDICIONES DE USO DEL SITIO WEB Y SERVICIOS",
  text_10: "5.1 Uso permitido",
  text_11:
    "Los usuarios del sitio web de La Empresa se comprometen a utilizar la plataforma y los servicios ofrecidos de manera responsable y conforme a la legislación aplicable. Está estrictamente prohibido:",

  list_8:
    "Utilizar el sitio para actividades ilícitas, fraudulentas o que perjudiquen a terceros.",
  list_9:
    "Introducir o distribuir virus informáticos, malware o cualquier otro código malicioso.",
  list_10:
    "Acceder sin autorización a sistemas, datos o información de La Empresa o de otros usuarios.",
  list_11:
    "Recolectar información personal de otros usuarios sin su consentimiento.",
  list_12:
    "Realizar acciones que puedan comprometer la seguridad, integridad o funcionamiento del sitio web.",

  text_12:
    "La Empresa se reserva el derecho de restringir, suspender o cancelar el acceso al sitio y a los servicios a aquellos usuarios o cuentas que incumplan estas disposiciones.",

  text_13: "5.2 Registro de usuarios",

  text_14:
    "Para acceder a ciertos servicios o funcionalidades, es posible que se requiera la creación de una cuenta de usuario. El proceso de registro implica:",
  list_13:
    "Proporcionar información veraz, completa y actualizada, incluyendo nombre completo, dirección de correo electrónico y otros datos relevantes.",
  list_14:
    "Elegir credenciales de acceso seguras y confidenciales. El usuario es responsable de mantener la confidencialidad de su contraseña y de todas las actividades que se realicen bajo su cuenta.",
  list_15:
    "Notificar de inmediato a La Empresa sobre cualquier uso no autorizado de la cuenta o cualquier otra brecha de seguridad.",

  text_15:
    "La Empresa no será responsable por pérdidas o daños derivados del incumplimiento de estas obligaciones por parte del usuario o del mal uso de sus credenciales.",

  text_16: "5.3 Propiedad Intelectual",
  text_17:
    "Todo el contenido presente en el sitio web de La Empresa, incluyendo, pero no limitado a textos, imágenes, gráficos, logotipos, íconos, videos, software y marcas registradas, es propiedad exclusiva de La Empresa o de sus licenciantes y está protegido por las leyes de propiedad intelectual vigentes.",
  text_18:
    "Queda prohibida la reproducción, distribución, modificación, exhibición pública, transmisión o cualquier otro uso del contenido sin el consentimiento previo y por escrito de La Empresa. El uso no autorizado puede resultar en acciones legales pertinentes.",

  subtitle_6: "6.	POLÍTICAS DE PAGO",
  text_19: "6.1 Medios de pago",
  text_20:
    "Los pagos se realizan directamente con la Empresa a través de los siguientes medios:",

  list_16: "Tarjetas de crédito y débito.",
  list_17: "Transferencias bancarias.",
  list_18: "Otros métodos acordados con la Empresa.",

  text_21:
    "El usuario es responsable de proporcionar información correcta al momento del pago. La Empresa puede rechazar transacciones en caso de sospecha de fraude.",

  text_22: "6.2 Facturación",
  text_23:
    "Para solicitar factura, el cliente deberá proporcionar sus datos fiscales en el plazo establecido por la legislación vigente. La Empresa enviará la factura al correo registrado.",

  text_24: "6.3 Procesamiento de pagos",
  text_25:
    "Una vez confirmado el pago, se procederá con la gestión del servicio o producto. Los tiempos pueden variar según el caso y serán informados oportunamente.",

  subtitle_7: "7.	LIMITACIÓN DE RESPONSABILIDAD",

  text_26_1: "7.1 Exclusión de garantías: ",
  text_26_2:
    "La Empresa no garantiza que el sitio web o los servicios estén libres de errores o interrupciones.",

  text_27_1: "7.2 Uso del servicio bajo responsabilidad del usuario: ",
  text_27_2:
    "El usuario asume la responsabilidad del uso de los productos y servicios adquiridos.",

  subtitle_8: "8.	PROTECCIÓN DE DATOS Y PRIVACIDAD",

  text_28_1: "8.1 Aviso de privacidad: ",
  text_28_2:
    "Se aplican las políticas de privacidad establecidas en el aviso correspondiente.",

  text_29_1: "8.2 Confidencialidad: ",
  text_29_2:
    "La información del usuario será protegida conforme a las leyes de privacidad aplicables y ",
  text_29_3: "no será compartida sin su consentimiento",
  text_29_4: ", salvo disposición legal en contrario.",

  subtitle_9: "9.	LEGISLACIÓN Y JURISDICCIÓN APLICABLE",

  text_30_1:
    "Estos Términos y Condiciones se rigen por la legislación vigente en los ",
  text_30_2: "Estados Unidos Mexicanos",
  text_30_3:
    ". Cualquier controversia derivada del presente documento será sometida a la jurisdicción de los tribunales competentes en la ",
  text_30_4: "Ciudad Madero, Tamaulipas.",
};

interface cookiesModalES {
  header: string;
  text_1: string;

  subtitle_1: string;
  text_2: string;

  subtitle_2: string;
  text_3: string;

  list_1_1: string;
  list_1_2: string;
  list_2_1: string;
  list_2_2: string;
  list_3_1: string;
  list_3_2: string;

  subtitle_3: string;
  list_4_1: string;
  list_4_2: string;
  list_5_1: string;
  list_5_2: string;
  list_6_1: string;
  list_6_2: string;
  list_7_1: string;
  list_7_2: string;

  subtitle_4: string;
  text_4: string;

  subtitle_5: string;
  text_5: string;

  subtitle_6: string;
  text_6: string;

  subtitle_7: string;
  text_7: string;

  subtitle_8: string;
  text_8: string;
  text_9_1: string;
  text_9_2: string;
  text_10_1: string;
  text_10_2: string;
  text_11_1: string;
  text_11_2: string;
  text_12: string;

  text_13_1: string;
  text_13_2: string;

  button_close: string;
}

export const cookiesModalES: cookiesModalES = {
  header: "Políticas de Cookies",
  text_1: `Bienvenido a GBCI Group S.A. de C.V. y sus marcas asociadas ("Nosotros", "nuestro", "nosotros", o "GBCI Group"). Utilizamos cookies y tecnologías similares cuando visitas nuestros sitios web y plataformas digitales. Al utilizar nuestros servicios, aceptas el uso de cookies según lo descrito en esta política.`,

  subtitle_1: "1.	¿Qué son las cookies? ",
  text_2:
    "Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestros sitios web. Estos archivos contienen información que se utiliza para mejorar la experiencia del usuario y recopilar datos sobre el comportamiento de navegación.",

  subtitle_2: "2.	¿Cómo utilizamos las cookies?",
  text_3: "GBCI Group utiliza cookies para los siguientes propósitos:",

  list_1_1: "Mejora de la experiencia del usuario: ",
  list_1_2:
    "Utiliza cookies para personalizar tu experiencia en nuestros sitios web, recordar tus preferencias y facilitar la navegación.",
  list_2_1: "Análisis y rendimiento: ",
  list_2_2:
    "Utilizamos cookies para recopilar datos analíticos que nos ayudan a entender cómo los usuarios interactúan con nuestros sitios web y mejorar su rendimiento.",
  list_3_1: "Publicidad personalizada: ",
  list_3_2:
    "Puede utilizar cookies para mostrar anuncios personalizados basados en tus intereses y comportamientos de navegación.",

  subtitle_3: "3.	Tipos de cookies que utilizamos:",
  list_4_1: "Cookies esenciales: ",
  list_4_2: "Necesarias para el funcionamiento básico del sitio web.",
  list_5_1: "Cookies de rendimiento: ",
  list_5_2:
    "Nos ayudan a mejorar la funcionalidad del sitio web recopilando información sobre cómo se utiliza.",
  list_6_1: "Cookies de funcionalidad: ",
  list_6_2:
    "Permiten que el sitio web recuerde tus preferencias y elecciones para proporcionar una experiencia personalizada.",
  list_7_1: "Cookies de publicidad: ",
  list_7_2: "Utilizadas para mostrar anuncios relevantes y personalizados.",

  subtitle_4: "4.	Control de cookies:",
  text_4:
    "Puedes gestionar las preferencias de cookies a través de la configuración de tu navegador. Ten en cuenta que la desactivación de ciertas cookies puede afectar la funcionalidad de nuestros sitios web.",

  subtitle_5: "5.	Compartir información con terceros:",
  text_5:
    "En algunos casos, GBCI Group puede compartir datos recopilados a través de cookies con terceros para mejorar nuestros servicios y ofrecer publicidad más relevante. Sin embargo, los datos recabados de los usuarios son exclusivamente para la base de datos de GBCI Group y no se transmiten a terceros.",

  subtitle_6: "6.	Relación con GBCI Group:",
  text_6:
    "Esta política se aplica a todos los dominios y activos intangibles propiedad de GBCI Group y/o sus filiales.",

  subtitle_7: "7.	Cambios en la política de cookies: ",
  text_7:
    "GBCI Group se reserva el derecho de modificar esta Política de Cookies en cualquier momento. Cualquier cambio significativo será notificado a través de nuestros sitios web.",

  subtitle_8: "8.	Contacto:",
  text_8:
    "Si tienes preguntas sobre esta Política de Cookies, puedes contactarnos a través de:",
  text_9_1: "Departamento responsable: ",
  text_9_2: "Tecnologías de la información",
  text_10_1: "Correo electrónico: ",
  text_10_2: "info@gbcigroup.com",
  text_11_1: "Teléfono: ",
  text_11_2: "+52 833 160 8894",
  text_12:
    "Al utilizar nuestros servicios, aceptas el uso de cookies de acuerdo con esta Política de Cookies.",

  text_13_1: "Fecha de última actualización: ",
  text_13_2: "24 de Marzo de 2025 ",

  button_close: "Cerrar",
};
