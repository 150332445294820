import { FC, useEffect, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import Button from "../common/button";
import { cookiesModalEN } from "@/assets/dictionaries/en/cookies-content";
import { cookiesModalES } from "@/assets/dictionaries/es/cookies-content";

interface CookieModal extends Pick<ModalProps, "handleClose"> {}

const CookiesModal: FC<CookieModal> = ({ handleClose }) => {
  const [cookiesContent, setCookiesContent] = useState(cookiesModalEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setCookiesContent(cookiesModalES);
        break;
      case "EN":
      default:
        setCookiesContent(cookiesModalEN);
        break;
    }
  }, []); // Only run on mount

  return (
    <Modal
      handleClose={handleClose}
      size="large"
      header={cookiesContent.header}
    >
      <section>
        <p className="text-base mb-4">{cookiesContent.text_1}</p>
        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_1}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_2}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_2}
        </p>
        <p className="pl-8 text-base mb-4 font-bold">{cookiesContent.text_3}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_1_1}</span>
              {cookiesContent.list_1_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_2_1}</span>
              {cookiesContent.list_2_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_3_1}</span>
              {cookiesContent.list_3_2}
            </p>
          </li>
        </ul>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_3}
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_4_1}</span>
              {cookiesContent.list_4_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_5_1}</span>
              {cookiesContent.list_5_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_6_1}</span>
              {cookiesContent.list_6_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{cookiesContent.list_7_1}</span>
              {cookiesContent.list_7_2}
            </p>
          </li>
        </ul>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_4}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_4}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_5}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_5}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_6}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_6}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_7}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_7}</p>

        <p className="pl-4 text-base mb-4 font-bold">
          {cookiesContent.subtitle_8}
        </p>
        <p className="pl-8 text-base mb-4">{cookiesContent.text_8}</p>
        <p className="pl-8 text-base mb-2">
          <span className="font-bold">{cookiesContent.text_9_1}</span>
          {cookiesContent.text_9_2}
        </p>
        <p className="pl-8 text-base mb-2">
          <span className="font-bold">{cookiesContent.text_10_1}</span>
          {cookiesContent.text_10_2}
        </p>
        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{cookiesContent.text_11_1}</span>
          {cookiesContent.text_11_2}
        </p>

        <p className="pl-8 text-base mb-8">{cookiesContent.text_12}</p>

        <p className="pl-8 text-base mb-4">
          <span className="font-bold">{cookiesContent.text_13_1}</span>
          {cookiesContent.text_13_2}
        </p>

        <div className="flex justify-center">
          <div>
            <Button onClick={handleClose}>{cookiesContent.button_close}</Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default CookiesModal;
