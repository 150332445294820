import { FC, useEffect, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import Button from "../common/button";
import { privacyModalEN } from "@/assets/dictionaries/en/cookies-content";
import { privacyModalES } from "@/assets/dictionaries/es/cookies-content";

interface PrivacyPolicyModal extends Pick<ModalProps, "handleClose"> {}

const PrivacyPolicyModal: FC<PrivacyPolicyModal> = ({ handleClose }) => {
  const [privacyContent, setPrivacyContent] = useState(privacyModalEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setPrivacyContent(privacyModalES);
        break;
      case "EN":
      default:
        setPrivacyContent(privacyModalEN);
        break;
    }
  }, []); // Only run on mount

  return (
    <Modal
      handleClose={handleClose}
      size="large"
      header={privacyContent.header}
    >
      <section>
        <p className="text-base mb-4">{privacyContent.text_1}</p>
        <p className="text-base mb-4">{privacyContent.text_2}</p>
        <p className="text-base mb-4">{privacyContent.text_3}</p>
        <p className="text-base mb-4">{privacyContent.text_4}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_1}
        </p>

        <p className="text-base mb-4">{privacyContent.text_5}</p>

        <p className="text-base mb-4 font-bold">{privacyContent.subtitle_1}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">{privacyContent.list_1}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_2}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_3}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_4}</p>
          </li>
        </ul>

        <p className="text-base mb-4 font-bold">{privacyContent.subtitle_2}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">{privacyContent.list_5}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_6}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_7}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_8}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_9}</p>
          </li>
        </ul>

        <p className="text-base mb-4">
          {privacyContent.text_6_1}
          <span className="font-bold">{privacyContent.text_6_2}</span>
          {privacyContent.text_6_3}
        </p>

        <p className="text-base mb-4">{privacyContent.text_7}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_2}
        </p>

        <p className="text-base mb-4">{privacyContent.text_8}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{privacyContent.list_10_1}</span>
              {privacyContent.list_10_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">
              <span className="font-bold">{privacyContent.list_11_1}</span>
              {privacyContent.list_11_2}
            </p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_12}</p>
          </li>
        </ul>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_3}
        </p>

        <p className="text-base mb-4">{privacyContent.text_9}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_4}
        </p>

        <p className="text-base mb-4">{privacyContent.text_10}</p>
        <p className="text-base mb-4">{privacyContent.text_11}</p>
        <p className="text-base mb-4">{privacyContent.text_12}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_5}
        </p>

        <p className="text-base mb-4">{privacyContent.text_13}</p>
        <p className="text-base mb-4">{privacyContent.text_14}</p>
        <p className="text-base mb-4">{privacyContent.text_15}</p>
        <p className="text-base mb-4">{privacyContent.text_16}</p>
        <p className="text-base mb-4">{privacyContent.text_17}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_6}
        </p>
        <p className="text-base mb-4">{privacyContent.text_18}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_7}
        </p>
        <p className="text-base mb-4">{privacyContent.text_19}</p>
        <p className="text-base mb-4">{privacyContent.text_20}</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <p className="text-base mb-4">{privacyContent.list_13}</p>
          </li>
          <li>
            <p className="text-base mb-4">{privacyContent.list_14_1}</p>
            <p className="text-base mb-4">{privacyContent.list_14_2}</p>
            <p className="text-base mb-4">{privacyContent.list_14_3}</p>
          </li>
        </ul>

        <p className="text-base mb-4">{privacyContent.text_21}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_8}
        </p>

        <p className="text-base mb-4">{privacyContent.text_22}</p>

        <p className="pl-4 text-base mb-4 underline font-bold">
          {privacyContent.guide_9}
        </p>

        <p className="text-base mb-4">{privacyContent.text_23}</p>

        <p className="text-base mb-4">{privacyContent.text_24}</p>

        <p className="text-base mb-4">{privacyContent.text_25}</p>
        <p className="text-base mb-4">{privacyContent.text_26}</p>
        <p className="text-base mb-4">{privacyContent.text_27}</p>
        <p className="text-base mb-4">{privacyContent.text_28}</p>

        <p className="text-base mb-4">{privacyContent.text_29}</p>

        <p className="text-base mb-4 font-bold">{privacyContent.text_30}</p>

        <p className="text-base mb-4">{privacyContent.text_31}</p>
        <p className="text-base mb-4">
          {privacyContent.text_32_1}
          <span className="font-bold underline">
            {privacyContent.text_32_2}
          </span>
          {privacyContent.text_32_3}
        </p>

        <p className="text-base mb-4 font-bold underline text-center">
          {privacyContent.title}
        </p>

        <p className="text-base mb-4">{privacyContent.text_33}</p>
        <p className="text-base mb-4">{privacyContent.text_34}</p>
        <p className="text-base mb-4">{privacyContent.text_35}</p>
        <p className="text-base font-bold">{privacyContent.text_36}</p>
        <p className="text-base mb-4">{privacyContent.text_37}</p>
        <p className="text-base mb-4">{privacyContent.text_38}</p>

        <div className="flex justify-center">
          <div>
            <Button onClick={handleClose}>{privacyContent.button_close}</Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default PrivacyPolicyModal;
